import type { MetaInfo } from 'vue-meta';
import { defineNuxtPlugin } from '@/types/nuxt2CompositionApiShims';

export default defineNuxtPlugin((context) => {
  const { head } = context.app;

  // яндекс метрика
  const YM: Partial<MetaInfo> = {
    script: [
      {
        type: 'text/javascript',
        innerHTML: `
          <!-- Yandex.Metrika counter -->
           (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
           m[i].l=1*new Date();
           for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
           k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
           (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
           ym(95207638, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true
           });
          <!-- /Yandex.Metrika counter -->
        `
      }
    ],
    noscript: [
      {
        innerHTML: `
        <!-- Yandex.Metrika counter -->
        <div><img src="https://mc.yandex.ru/watch/95207638" style="position: absolute; left: -9999px;" alt="" /></div>
        <!-- /Yandex.Metrika counter -->
        `,
        body: true
      }
    ]
  };

  const YM_93264381 = {
    script: [
      {
        type: 'text/javascript',
        innerHTML: `
          <!-- Yandex.Metrika counter -->
           (function (d, w, c) {
        (w[c] = w[c] || []).push(function() {
            try {
                w.yaCounter93264381 = new Ya.Metrika({
                    id:93264381,
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    webvisor:true,
                    trackHash:true,
                    ecommerce:"dataLayer"
                });
            } catch(e) { }
        });

        var n = d.getElementsByTagName("script")[0],
            x = "https://mc.yandex.ru/metrika/watch.js",
            s = d.createElement("script"),
            f = function () { n.parentNode.insertBefore(s, n); };
        for (var i = 0; i < document.scripts.length; i++) {
            if (document.scripts[i].src === x) { return; }
        }
        s.type = "text/javascript";
        s.async = true;
        s.src = x;

        if (w.opera == "[object Opera]") {
            d.addEventListener("DOMContentLoaded", f, false);
        } else { f(); }
    })(document, window, "yandex_metrika_callbacks");

          <!-- /Yandex.Metrika counter -->
        `
      }
    ],
    noscript: [
      {
        innerHTML: `
        <!-- Yandex.Metrika counter -->
        <div><img src="https://mc.yandex.ru/watch/93264381" style="position: absolute; left: -9999px;" alt="" /></div>
        <!-- /Yandex.Metrika counter -->
        `,
        body: true
      }
    ]
  };


  const GTM: Partial<MetaInfo> = {
    script: [
      {
        innerHTML: `
          <!-- Google Tag Manager -->
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MR9TQWJ');
          <!-- End Google Tag Manager -->
        `
      }
    ],
    noscript: [
      {
        innerHTML: `
          <!-- Google Tag Manager (noscript) -->
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MR9TQWJ"
        height="0" width="0" style="display: none;visibility: hidden;"></iframe>
        <!-- End Google Tag Manager (noscript) -->
        `,
        body: true
      }
    ]
  };


  const GA: Partial<MetaInfo> = {
    script: [
    // GA start
      {
        innerHTML: `
            (function(i,s,o,g,r,a,m){i["GoogleAnalyticsObject"]=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,"script","https://www.google-analytics.com/analytics.js","ga");
            window.ga("create", "UA-111285949-5", "auto");
            setTimeout(function(){ window.ga("send", "pageview"); }, 1000)
          `
      }
    ]
  };

  const Smartys: Partial<MetaInfo> = {
    script: [
      {
        innerHTML: `
            (function(i,s,o,g,r,a,m){i["GoogleAnalyticsObject"]=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,"script","https://www.google-analytics.com/analytics.js","ga");
            window.ga("create", "UA-111285949-5", "auto");
            setTimeout(function(){ window.ga("send", "pageview"); }, 1000)
          `
      },

      // Smartys Analytics scripts start
      {
        innerHTML: `
          var _paq = _paq || []; _paq.push(["setCookieDomain", "*.strana.com"]); _paq.push(["setDomains", ["*.strana.com"]]); _paq.push(["trackPageView"]); _paq.push(["enableLinkTracking"]); (function() { var u="//collect.smartanalytics.io/"; var u2="//smartanalytics.io/"; _paq.push(['setTrackerUrl', u+'smart.php']); _paq.push(['enableHeartBeatTimer',15]); _paq.push(["setSiteId", "M1B6q18"]); var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0]; g.type='text/javascript'; g.async=true; g.defer=true; g.src=u2+'js/smart.js'; s.parentNode.insertBefore(g,s); })();
          `
      },
      {
        innerHTML: `
          (function () {
            var counter = 0, interval = setInterval(function () {
              if (++counter > 59)
                clearInterval(interval);
              if (window.Comagic && window.Comagic.setProperty && window.Comagic.hasProperty && window._paq && window._paq.push)
                window._paq.push([function () {
                  window.Comagic.setProperty("smart_visitor_id", this.getVisitorId());
                  window.Comagic.hasProperty("smart_visitor_id", function (resp) {
                    if (resp === true)
                      clearInterval(interval);
                  });
                }]);
            }, 1000);
          })();
          `,
        body: true
      },
      {
        innerHTML: `
          (function () {
              var d = document, g = d.createElement("script"), b = d.body;
              g.type = 'text/javascript';
              g.async = true;
              g.defer = true;
              g.src = '//smartanalytics.io/js/crm_cookie_middle.js';
              b.appendChild(g);
          })();
          `,
        body: true
      }
    ],
    noscript: [
      // Smartys Analytics noscripts
      {
        innerHTML: `
          <p><img src="//collect.smartanalytics.io/smart.php?idsite=M1B6q18" style="border: 0;" alt="" /></p>
          `,
        body: true
      }
    ]
  };

  const ChatWidget: Partial<MetaInfo> = {
    script: [
      {
        innerHTML: `
          var _tbEmbedArgs = _tbEmbedArgs || [];
          (function () {
            var u =  "https://widget.textback.io/widget";
            _tbEmbedArgs.push(["widgetId", "79b5c34e-3e8f-47b5-9449-a3bb69ec0f20"]);
            _tbEmbedArgs.push(["baseUrl", u]);
          
            var d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
            g.type = "text/javascript";
            g.charset = "utf-8";
            g.defer = true;
            g.async = true;
            g.src = u + "/widget.js";
            s.parentNode.insertBefore(g, s);
          })();
        `,
        body: true
      }
    ]
  };

  const uxFeedback: Partial<MetaInfo> = {
    script: [
      {
        innerHTML: `
          (function(w, d, u, h, s) {
          w._uxsSettings = {id: '8gcjcqmw5cizdfp70b3gw7rz'};
          h = d.getElementsByTagName('head')[0];
          s = d.createElement('script');
          s.async = 1;
          s.src = u;
          h.appendChild(s);
          })(window, document, 'https://cdn.uxfeedback.ru/widget.js');
        `,
        body: true
      }
    ]
  };

  // для отображения 3д тура в сделках (ДСЛ)
  const Planoplan: Partial<MetaInfo> = {
    script: [
      {
        src: 'https://widget.planoplan.com/etc/multiwidget/release/static/js/main.js',
        defer: true
      }
    ]
  };

  const Calltouch: Partial<MetaInfo> = {
    script: [
      {
        innerHTML: `
          (function(f, d, t, e) {
              f.CalltouchDataObject = t;
              var n = "callbacks";
              f[t] = function() {
                  f[t][n].push(arguments)
              };
              if (!f[t][n]) {
                  f[t][n] = []
              }
              f[t]["loaded"] = false;
              if (typeof e !== "object") {
                  e = [e]
              }
              f[t]["counters"] = e;
              for (var o = 0; o < e.length; o += 1) {
                  s(e[o])
              }
          
              function s(e, n) {
                  var t = "script";
                  var o = d.getElementsByTagName(t)[0],
                      a = d.createElement(t),
                      r = function() {
                          o.parentNode.insertBefore(a, o)
                      },
                      c = typeof Array.prototype.find === "function",
                      i = c ? "init-min.js" : "init.js";
                  a.async = true;
                  a.src = "https://" + (n || "mod.calltouch") + ".ru/" + i + "?id=" + e;
                  a.onerror = function() {
                      var t = "ct-md";
                      if (n !== t) s(e, t)
                  };
                  if (f.opera == "[object Opera]") {
                      d.addEventListener("DOMContentLoaded", r, false)
                  } else {
                      r()
                  }
              }
          })(window, document, "ct", "om6ni2v1");
        `,
        body: true
      }
    ]
  };

  const globalHeads = [] as Array<Partial<MetaInfo>>;


  globalHeads.push(ChatWidget, Smartys, GA, Planoplan);

  if (context.$config.ENVIRONMENT === 'production') {
    globalHeads.push(YM, YM_93264381, GTM, uxFeedback, Calltouch);
  } else {
    const { query } = context.route;

    if ('ym_analytics' in query) {
      globalHeads.push(YM);
    }

    if ('ym_93264381' in query) {
      globalHeads.push(YM_93264381);
    }

    if ('gtm_analytics' in query) {
      globalHeads.push(GTM);
    }

    if ('ux_feedback' in query) {
      globalHeads.push(uxFeedback);
    }

    if ('calltouch' in query) {
      globalHeads.push(Calltouch);
    }
  }

  if (globalHeads.length) {
    if (!head) {
      return;
    }

    // сложная типизация (в т.ч. у MetaInfo), мб когда-нибудь что-то с этим сделать...
    if (typeof head !== 'function') {
      head.__dangerouslyDisableSanitizers = ['script', 'noscript'];
      globalHeads.forEach((item) => {
        const _head = head as Record<string, Array<unknown>>;
        (Object.keys(item) as Array<keyof MetaInfo>).forEach((key) => {
          const currentItem = item[key];

          if (Array.isArray(currentItem)) {
            if (!_head[key]) {
              _head[key] = [];
            }

            _head[key] = [..._head[key], ...currentItem];
          }
        });
      });
    } else {
      console.warn('head was a function');
    }
  }
});
