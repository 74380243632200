import { defineStore } from 'pinia';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import type {
  UploadedDocuments,
  FileFromBackend
} from '@/types/UploadDocuments';
import {
  DocumentSlug
} from '@/types/Mortgage';


interface State {
  uploadedDocuments?: UploadedDocuments;
  documentsSlug: Array<DocumentSlug>;
}

export const useUploadDocuments = defineStore('uploadDocuments', {
  state: (): State => ({
    uploadedDocuments: undefined,
    documentsSlug: []
  }),

  getters: {
    getDocumentSlug: (state) => (documentId: number): string | undefined => {
      const document = state.documentsSlug.find(({ id }) => id === documentId);

      return document?.slug;
    }
  },

  actions: {
    async getDocumentsSlug (): Promise<void> {
      try {
        const { data } = await this.$axios.get<Array<DocumentSlug>>('/api/questionnaire/documents/slug');

        this.documentsSlug = data;
      } catch (error) {
        console.error('🚀 ~ file: uploadDocuments.ts ~ getDocumentsSlug ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    setUploadedDocuments (documentId: number, data: Array<FileFromBackend>): void {
      const documentSlug = this.getDocumentSlug(documentId);

      if (!documentSlug) {
        this.$sentry.captureMessage('uploadDocuments.ts ~ setUploadedDocuments ~ document slug not found', {
          tags: {
            cabinet: 'error'
          }
        });

        return;
      }

      if (this.uploadedDocuments) {
        this.uploadedDocuments[documentSlug] = data;
      }
    },

    async uploadDocuments (
      formData: FormData | null,
      documentId: number,
      bookingId?: number,
      mortgageTicketId?: number,
      participantTypeSlug?: string,
      participantNumber?: number | string
    ): Promise<void> {
      if (!bookingId) {
        return;
      }

      try {
        const { data } = await this.$axios.post('/api/v2/documents/upload-documents', formData, {
          headers: {
            'Content-Type': formData ? 'multipart/form-data' : 'application/json'
          },
          params: {
            bookingId,
            documentId,
            mortgageTicketId,
            participantTypeSlug,
            participantNumber
          }
        });

        if (!participantTypeSlug) {
          this.setUploadedDocuments(documentId, data);
        }
      } catch (error: unknown) {
        console.error('🚀 ~ file: uploadDocuments.ts ~ uploadDocuments ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        this.$notify.create({
          message: errorMessage,
          type: 'negative'
        });
        this.setUploadedDocuments(documentId, []);
      }
    },

    async getUploadedDocuments (slugs: string, bookingId?: number): Promise<void> {
      if (!bookingId) {
        return;
      }

      try {
        const { data } = await this.$axios.get<UploadedDocuments>('/api/documents/uploaded-documents', {
          params: {
            bookingId,
            slugs
          }
        });

        this.uploadedDocuments = data;
      } catch (error) {
        console.error('🚀 ~ file: uploadDocuments.ts ~ getUploadedDocuments ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async deleteDocuments (ids: Array<string>, documentId: number, bookingId?: number): Promise<void> {
      if (!bookingId) {
        return;
      }

      try {
        const { data } = await this.$axios.patch('/api/documents/delete-documents', {
          file_ids: ids,
          booking_id: bookingId,
          document_id: documentId
        });

        this.setUploadedDocuments(documentId, data);
      } catch (error) {
        console.error('🚀 ~ file: uploadDocuments.ts ~ deleteDocuments ~ error', error);
        this.$sentry.captureException(error);
      }
    }
  }
});
