// Стор для feature flags
// import { useFeatureFlagsStore, FLAGS } from '~/store/featureFlags';
// При переходе между страницами с бэка запрашиваем список флагов
// В компоненте используем геттер
// setup () {
//   const featureFlagsStore = useFeatureFlagsStore();
//   const { isEnabledFlag } = storeToRefs(featureFlagsStore);
//   return {
//     isEnabledFlag
//   };
// },
// Использовать
// v-if = isEnabledFlag(FLAGS[slug])

import { defineStore } from 'pinia';

type Flags = Record<string, boolean>;

/* список флагов которые используются в проекте */
export const FLAGS:Record<string, string> = {
  favorite: 'strana_lk-3542',
  crossedPrice: 'strana_lk-4043', // зачеркнутая цена
  repeatV2: 'strana_lk-4399',
  newServices: 'strana_lk-4198'
};

const flags: Array<string> = [
  FLAGS.favorite,
  FLAGS.crossedPrice,
  FLAGS.repeatV2,
  FLAGS.newServices
];

interface State {
  featureFlags: Flags;
}

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: (): State => ({
    featureFlags: {}
  }),

  actions: {
    async getFlags (): Promise<void> {
      try {
        const url: string = 'api/feature-flags';
        const {
          data
        } = await this.$axios.get(url, {
          params: {
            feature_flag: flags
          }
        });

        this.featureFlags = {
          ...data
        };
      } catch (error) {
        console.error('🚀 ~ file: featureFlags.ts ~ getFlags ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    isEnabledFlag (flag: string): boolean {
      return Boolean(this.featureFlags[flag]);
    }
  }
});
