import Vue, {
  type UnwrapNestedRefs,
  reactive
} from 'vue';
// eslint-disable-next-line
// @ts-ignore
import {
  VueScreen,
  type ScreenType
} from '@strana-artw/common';

import { SCREEN_BREAKPOINTS } from '@@/src/app/config/screen';

declare module 'vue/types/vue' {
  interface Vue {
    $screen: ScreenType;
  }
}

interface Device {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  isMobileOrTablet?: boolean;
  isTabletOrDesktop?: boolean;
}

let screenPlugin: UnwrapNestedRefs<ScreenType> | undefined;

export default ({ $device = {} satisfies Device }):void => {
  Vue.use({ install: VueScreen.install }, {
    device: $device,
    breakpoints: SCREEN_BREAKPOINTS
  });

  screenPlugin = reactive(Vue.prototype.$screen);
};

export const useScreenPlugin = ():ScreenType => {
  if (!screenPlugin) {
    throw new Error('Screen plugin is not defined.');
  }

  return screenPlugin;
};
